function moment_setter()
{
	moment.locale("ru");
	$('[data-momentdate]').each(function(i, item){
		var u = $(item).attr("data-momentdate");
		var f = $(item).attr("data-momentformat");
		if (!f) {
			f = "D MMM YYYY [г. в] HH:mm";
		}
		var m = moment(u, "X").format(f);
		if($(item).is("input")) {
			$(item).val(m);
		} else {
			$(item).text(m);
		}
	});
}